import { Lane } from "./lane.model";
import { LaneService } from "./lane.service";
import { signal } from "@angular/core";

export class LaneMockService implements LaneService {
  
    public dataChangesAreMonitored = signal<boolean>(false);

    public data = signal<Lane[]>([]);

    private mockData: Lane[] = [
        {
            "laneId": "einfahrt",
            "name": "Berlin Kaiserdamm - Einfahrt",
            "previewCameraUrl": "https://stream:t!KRjEk$hJa2atCQ@on0bvlscam01.de-b.bmwgroup.net/axis-cgi/mjpg/video.cgi?streamprofile=Preview",
            "fullScreenCameraUrl": "https://stream:t!KRjEk$hJa2atCQ@on0bvlscam01.de-b.bmwgroup.net/axis-cgi/mjpg/video.cgi?streamprofile=Full",
            "direction": "in",
            "placeholderImageUrl": "http://example.com/image1.png",
            "barrierControlUrl": "http://example.com/barrier1"
        },
        {
            "laneId": "ausfahrt",
            "name": "Berlin Kaiserdamm - Ausfahrt",
            "previewCameraUrl": "https://stream:t!KRjEk$hJa2atCQ@on0bvlscam02.de-b.bmwgroup.net/axis-cgi/mjpg/video.cgi?streamprofile=Preview",
            "fullScreenCameraUrl": "https://stream:t!KRjEk$hJa2atCQ@on0bvlscam02.de-b.bmwgroup.net/axis-cgi/mjpg/video.cgi?streamprofile=Full",
            "direction": "out",
            "placeholderImageUrl": "http://example.com/image2.png",
            "barrierControlUrl": "http://example.com/barrier2"
        }
    ];

    public async loadAll(): Promise<Lane[]> {
      const newData = structuredClone(this.mockData);
      this.data.set(newData);
      return newData;
    }
}