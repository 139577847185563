import { Component, OnDestroy, ViewChild, computed, effect, signal } from '@angular/core';
import { DsBoxModule, DsButtonModule, DsCheckboxModule, DsFormFieldModule, DsIconModule, DsLoadingAreaConfiguration, DsLoadingAreaModule, DsLoadingAreaOverlayContainerDirective, DsLoadingAreaService, DsPopoverModule, DsSelectModule, DsToastModule, DsToastService } from '@bmw-ds/components';
import { VehicleAgGridComponent } from '../vehicle-ag-grid/vehicle-ag-grid.component';
import { PairingDialogComponent } from '../pairing-dialog/pairing-dialog.component';
import { FormsModule } from '@angular/forms';
import GridExportEvent from '../visitors-and-appointments/grid-export-event';
import { VehicleStayService } from '@bmw-spp/bmw-spp-frontend-common';

@Component({
  selector: 'app-vehicles',
  standalone: true,
  imports: [
    DsToastModule,
    DsLoadingAreaModule,
    DsBoxModule,
    DsIconModule,
    DsPopoverModule,
    DsSelectModule,
    VehicleAgGridComponent,
    PairingDialogComponent,
    DsToastModule,
    DsLoadingAreaModule,
    DsCheckboxModule,
    DsFormFieldModule,
    FormsModule,
    DsButtonModule
  ],
  templateUrl: './vehicles.component.html',
  styleUrl: './vehicles.component.scss'
})
export class VehiclesComponent implements OnDestroy {
  isLoading = signal(true);
  viewSelectionAll = signal(true);
  viewSelectionIsNotMarried = signal(false);
  viewSelectionIsOnArea = signal(false);
  groupModeIsActive = signal(false);
  selectedFilterType = signal('');
  numberOfEntries = signal(0);
  textFilter = signal('');

  vehicle = computed(() => {
    let data = this.vehicleStayService.data();
    if (this.viewSelectionAll()) {
      return data;
    }
    if (this.viewSelectionIsNotMarried()) {
      data = data.filter(stays => typeof stays.vehicle.pairing === "undefined" || stays.vehicle.pairing === null);
    }
    if (this.viewSelectionIsOnArea()) {
      data = data.filter(stays => (typeof stays.transitedDatetime !== "undefined" && stays.transitedDatetime !== null) &&
                                  (typeof stays.exitedDatetime === "undefined" || stays.exitedDatetime === null || stays.exitedDatetime < stays.transitedDatetime));
    }
    return data;
  });

  @ViewChild(DsLoadingAreaOverlayContainerDirective)
  loadingAreaContainer!: DsLoadingAreaOverlayContainerDirective;

  private static instance = 0;
  private loadingAreaID = `VehiclesAllComponent-${VehiclesComponent.instance++}`;

  private loadingAreaConfig: DsLoadingAreaConfiguration = {
    id: this.loadingAreaID,
    container: this.loadingAreaContainer,
  }

  constructor(
    private vehicleStayService: VehicleStayService,
    private toastService: DsToastService,
    private loadingAreaService: DsLoadingAreaService,
    private gridExportEvent: GridExportEvent,
  ) {
    effect(() => {
      if (this.isLoading()) {
        this.loadingAreaService.addLoadingArea(this.loadingAreaConfig);
      } else {
        this.loadingAreaService.removeLoadingArea(this.loadingAreaID);
      }
    });
    this.loadData();
  }

  async loadData() {
    try {
      await this.vehicleStayService.loadAll();
    } catch (err) {
      this.toastService.pushToast({
        id: 'error-toast',
        tone: 'critical',
        toastText: 'Fehler beim Laden. Bitte versuchen Sie es später erneut.'
      })
    } finally {
      this.isLoading.set(false);
    }
  }

  ngOnDestroy(): void {
    this.toastService.clearToasts();
  } public showPairingDialog = signal(false);

  public handlePairingClick(): void {
    this.showPairingDialog.set(true);
  }

  public closeModal(): void {
    this.showPairingDialog.set(false);
  }

  setViewSelectionAll(value: boolean) {
    this.viewSelectionAll.set(value);
    if (value) {
      this.viewSelectionIsNotMarried.set(false);
      this.viewSelectionIsOnArea.set(false);
    }
  }

  setViewSelectionIsMarried(value: boolean) {
    this.viewSelectionIsNotMarried.set(value);
    if (value) {
      this.viewSelectionAll.set(false);
    }
  }

  setViewSelectionIsOnArea(value: boolean) {
    this.viewSelectionIsOnArea.set(value);
    if (value) {
      this.viewSelectionAll.set(false);
    }
  }
  setGroupModeisActive(value: boolean) {
    this.groupModeIsActive.set(value);
    if (value) {
      this.viewSelectionAll.set(false);
    }
  }

  public exportGridToCsv(): void {
    this.gridExportEvent.emitEvent({});
  }
}
