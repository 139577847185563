import { PairingBackendService } from "../app/shared/pairing/pairing.backend.service";
import { Environment } from "./ienvironment";
import { PairingService } from "../app/shared/pairing/pairing.service";
import { DateService } from "../app/shared/date/date.service";
import { LaneMockService } from "../app/shared/streams/lane.mock.service";
import { LaneService } from "../app/shared/streams/lane.service";
import { LocationProviderService } from "../app/shared/location-provider/location-provider.service";
import { LocationProviderBackendService } from "../app/shared/location-provider/location-provider.backend.service";
import { StatisticsService } from "../app/shared/statistics/statistics.service";
import { AppointmentBackendService, AppointmentService, VehicleStayBackendService, VehicleStayService } from "@bmw-spp/bmw-spp-frontend-common";

export const environment: Environment = {
  environmentName: 'Dev',
  apiUrl: 'https://spp-backend.c2-cloud.de/grt/',
  wsUrl: 'wss://spp-backend.c2-cloud.de/grt/',
  reconnect: {
    retries: 10,
    delayInSeconds: 5000,
  },
  providers: [
    { provide: VehicleStayService, useClass: VehicleStayBackendService },
    { provide: PairingService, useClass: PairingBackendService },
    { provide: DateService, useClass: DateService },
    { provide: LaneService, useClass: LaneMockService },
    { provide: AppointmentService, useClass: AppointmentBackendService },
    { provide: LocationProviderService, useClass: LocationProviderBackendService },
    { provide: StatisticsService, useClass: StatisticsService },
  ],
  authEnabled: false,
  clientId: "c2983a8f-ca78-4bd3-b923-940bd716e50a",
  issuer: "https://auth-i.bmwgroup.net:443/auth/oauth2/realms/root/realms/internetb2xmfaonly",
  secureRouteRootUrl: "https://spp-backend.c2-cloud.de/grt/",
  redirectURL: "https://spp-grt.c2-cloud.de/",
};
