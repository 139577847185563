import { Injectable } from "@angular/core";
import { PairingService } from "./pairing.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { PairingUpsert } from "./pairing-upsert.model";
import { firstValueFrom } from "rxjs";
import { VehicleStayService } from "@bmw-spp/bmw-spp-frontend-common";

@Injectable()
export class PairingBackendService extends PairingService {
  private apiUrl = environment.apiUrl + 'v1/vehicle';

  constructor(
    private http: HttpClient,
    vehicleService: VehicleStayService,
  ) {
    super(vehicleService);
  }

  public override async startUpsertPairing(licensePlate: string, pairing: PairingUpsert, isNew: boolean): Promise<void> {
    if (!licensePlate || !pairing) {
      throw new Error('licensePlate and PairingCommand object are required');
    }

    const url: string = `${this.apiUrl}/pair/${licensePlate}`;
    if (isNew) {
      await firstValueFrom(this.http.post(url, pairing));
    } else {
      await firstValueFrom(this.http.put(url, pairing));
    }
  }
}
