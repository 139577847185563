<ds-box>
    <ds-box-header>
        <div class="row">
            <div class="col-10">
                <h3>{{stream.name}}</h3>
            </div>
            <div class="d-flex col-2 justify-content-end">
                <a *ngIf="stream.barrierControlUrl" ds-button variant="primary" icon="settings" target="_blank" [href]="stream.barrierControlUrl" aria-label="Schrankensteuerung"></a>
            </div>
        </div>
    </ds-box-header>
    <ds-box-content>
        <a [href]="fullScreenSourceUrl()" target="_blank" title="im Vollbild öffnen">
            <img [src]="previewSourceUrl()" alt="video stream" class="stream">
        </a>
        <!-- <video autoplay="true" controlslist="nodownload" muted="true" controls></video>
            <source [src]="url()" type="video/mp4"  /> -->
            <!-- <source media="(min-width:1000px)" [src]="stream.fullScreenCameraUrl" type="video/mp4"> -->
        <!-- </video> -->
    </ds-box-content>
</ds-box>
