import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsButtonModule, DsToastService } from '@bmw-ds/components';
import { DsIconModule } from '@bmw-ds/components';
import { PairingDialogComponent } from '../pairing-dialog/pairing-dialog.component';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { PairingDialogRoles } from '../shared/pairing/pairing-dialog-roles.model';
import { VehicleStay, VehicleStayService } from '@bmw-spp/bmw-spp-frontend-common';


@Component({
  selector: 'app-button-cell-renderer',
  standalone: true,
  imports: [
    CommonModule,
    DsButtonModule,
    DsIconModule,
    PairingDialogComponent,
  ],
  templateUrl: './vehicle-all-button-cell-renderer.component.html',
  styleUrl: './vehicle-all-button-cell-renderer.component.scss'
})
export class VehicleAllButtonCellRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams<VehicleStay>;
  vehicleService!: VehicleStayService;
  pairingDialogRole = PairingDialogRoles.Pairing;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onClick(): void {
    this.showPairingDialog.set(true);
  }

  public showPairingDialog = signal(false);

  public constructor(
    private toastService: DsToastService
  ) {
  }

  public closeModal(): void {
    this.showPairingDialog.set(false);
  }

  public handlePairingResult(): void {
    this.showPairingDialog.set(false);
  }

}
