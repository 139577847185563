<ds-modal #pairingFormModal [ds-modal-trigger-for]="pairingFormModal" [(isModalOpen)]="showModal" (dismiss)="dismissModal()" size="sm">
        <ds-box-header>Verheiraten - {{license_plate()}}</ds-box-header>
        <ds-box-content>
            <form ds-form (ngSubmit)="onSubmit()" #pairingForm="ngForm">
                <ds-form-field class="mb-4x" *ngIf="dialogRole() === 'pairing'">
                    <label ds-label for="licensePlateField">Kennzeichen</label>
                    <input
                        ds-input
                        ds-form-validation
                        [ngModel]="license_plate()"
                        [readonly]="true"
                        name="licensePlateField"
                        #licensePlateField="ngModel"
                        type="text"
                        placeholder="Geben Sie hier ein Kennzeichen ein"
                        required />
                    <ds-error *ngIf="licensePlateField.hasError('required')">Bitte geben Sie ein Kennzeichen an</ds-error>
                </ds-form-field>
                <ds-form-field class="mb-4x" *ngIf="(dialogRole() === 'pairing' || dialogRole() === 'changeServiceReason')">
                    <label ds-label for="serviceReasonField">Besuchsgrund</label>
                    <ds-select 
                        [options]="serviceOptions"
                        ds-form-validation
                        #serviceReasonField="ngModel"
                        (optionSelected)="handleServiceSelection($event)"
                        name="serviceReasonField"
                        required
                        [ngModel]="reason()"
                        ></ds-select>
                        <ds-error *ngIf="serviceReasonField.hasError('required')">Bitte geben Sie einen Besuchsgrund an</ds-error>
                </ds-form-field>
                <ds-form-field class="mb-4x" *ngIf="reason()==='GA' && dialogRole() === 'pairing'">
                    <label ds-label for="vinField">VIN</label>
                    <input
                        ds-input
                        ds-form-validation
                        [ngModel]="vin()"
                        name="vinField"
                        #vinField="ngModel"
                        type="text"
                        placeholder="Geben Sie hier die vin ein"
                        required />
                    <ds-error *ngIf="vinField.hasError('required')">Bitte geben Sie eine VIN an</ds-error>
                </ds-form-field>
                <ds-form-field [hidden]="!reason" class="mb-4x"  *ngIf="dialogRole() === 'pairing'">
                    <label ds-label for="guideNumberField">Leitzahl</label>
                    <input
                        ds-input
                        ds-form-validation
                        [(ngModel)]="guideNumber"
                        name="guideNumberField"
                        #guideNumberField="ngModel"
                        type="number"
                        placeholder="Geben Sie hier eine Leitzahl ein"
                        appValidLocationName
                        [ngModelOptions]="{updateOn: 'blur'}"
                        required />
                    <ds-error *ngIf="guideNumberField.hasError('required')">Bitte geben Sie eine Leitzahl an</ds-error>
                    <ds-error *ngIf="guideNumberField.hasError('validName')">Ungültige Leitzahl</ds-error>
                </ds-form-field>
                <ds-form-field class="mb-4x" *ngIf="reason() === 'Service' && (dialogRole() === 'pairing' || dialogRole() === 'customerIsWaiting')">
                    <ds-checkbox-group>
                        <input
                            ds-input
                            [(ngModel)]="customerIsWaiting"
                            name="customerIsWaitingField"
                            [id]="uniqueInstanceId + '-waiting'"
                            type="checkbox"
                            aria-label="Checkbox für Kunde wartet" />
                        <label ds-label [for]="uniqueInstanceId + '-waiting'">Kunde wartet</label>
                    </ds-checkbox-group>
                </ds-form-field>
                <div class="d-flex justify-content-end">
                    <button ds-button [icon]="saveButtonText() === 'Speichern' ? '' : 'link'" type="submit" [disabled]="!pairingForm.valid" class="me-2x">{{saveButtonText()}}</button>
                    <button ds-button variant="outline" (click)="dismissModal()" type="button">Abbrechen</button>
                </div>
            </form>
        </ds-box-content>
    </ds-modal>