import { Component } from '@angular/core';
import { DsButtonModule } from '@bmw-ds/components';
import { Lane } from '../shared/streams/lane.model';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-actions-cell-renderer',
  standalone: true,
  imports: [
    DsButtonModule,
  ],
  templateUrl: './actions-cell-renderer.component.html',
  styleUrl: './actions-cell-renderer.component.scss'
})
export class ActionsCellRendererComponent {
  params?: ICellRendererParams<undefined, {lane: Lane}>;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }
}
