import { Component, Input } from '@angular/core';
import { DsBoxModule, DsCheckboxModule } from '@bmw-ds/components';
import { Lane } from '../shared/streams/lane.model';
import { CommonModule } from '@angular/common';
import { StreamCardComponent } from '../stream-card/stream-card.component';

@Component({
  selector: 'app-streams',
  standalone: true,
  imports: [
    CommonModule,
    DsBoxModule,
    StreamCardComponent,
    DsCheckboxModule
],
  templateUrl: './streams.component.html',
  styleUrl: './streams.component.scss'
})
export class StreamsComponent {
  @Input() streams: Lane[] = [];
  @Input() isVehiclesListSelected: boolean = false;
  @Input() isAppointmentsListSelected: boolean = false;

  constructor() {}
  
  public getColClass() {
    if (this.isVehiclesListSelected) {
      return 'col-12';
    } else {
      return 'col-12 col-md-6';
    }
  }
}
