<h1>Fahrstraßen</h1>
<div class="row mb-5x">
    <div class="col-9">
        <ds-form-field [optionalText]="optionalText">
            <ds-checkbox-group [isBlock]="true" variant="inline">
                <ng-container *ngFor="let stream of streamService.data()">
                    <input ds-input [id]="'stream-' + stream.laneId" type="checkbox" [name]="'stream-' + stream.laneId" [value]="true" [ngModel]="selectedStreams().includes(stream)" (ngModelChange)="toggleSelectedStream(stream)" aria-label="Checkbox for lane" />
                    <label ds-label [for]="'stream-' + stream.laneId">{{stream.name}}</label>
                </ng-container>
            </ds-checkbox-group>
        </ds-form-field>
    </div>
    <div class="d-flex col-3 justify-content-end">
        <button ds-toggle-button (checkedChange)="handleStreamsSelection($event)" [checked]="viewSelection().streams" icon="video_camera">Streams</button>
        <button ds-toggle-button (checkedChange)="handleListSelection($event)" [checked]="viewSelection().list" icon="table">Liste</button>
        <button ds-toggle-button (checkedChange)="handleAppointmentsSelection($event)" [checked]="viewSelection().appointments" icon="calendar_date">Termine</button>
    </div>
</div>
<div class="row">
    <div *ngIf="viewSelection().streams"
        [class]="streamsColClass()">
        <app-streams [streams]="selectedStreams()" [isAppointmentsListSelected]="viewSelection().appointments" [isVehiclesListSelected]="viewSelection().list"></app-streams>
    </div>
    <div *ngIf="viewSelection().list"
        [class]="listColClass()">
        <app-vehicles-list [lanes]="selectedStreams()"></app-vehicles-list>
    </div>
    <div *ngIf="viewSelection().appointments"
        [class]="appointmentColClass()">
        <app-appointments-list></app-appointments-list>
    </div>
</div>
