import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as echarts from 'echarts';
import BarChartSeries from './bar-chart-series.model';
import { StatisticsService } from '../../shared/statistics/statistics.service';
import { DsToastService } from '@bmw-ds/components';

@Component({
  selector: 'app-bar-chart',
  standalone: true,
  imports: [],
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.scss'
})
export class BarChartComponent implements OnChanges, AfterViewInit {
  @ViewChild('barChart') barChart: ElementRef | null = null;
  private barChartInstance: echarts.ECharts | null = null;
  @Input()
  public chartSeries: BarChartSeries[] = [];
  @Input()
  public data: { timerange: string, Service: number, Gast: number, GA: number }[] = [];

  constructor(private statisticsService: StatisticsService,
    private toastService: DsToastService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      try {
        this.refreshBarchart();
      } catch (err) {
        this.toastService.pushToast({
          id: 'error-toast',
          tone: 'critical',
          toastText: 'Fehler beim Aktualisieren des Diagramms.'
        });
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.barChart) {
      try {
        // First set height of chart
        this.barChart.nativeElement.style.height = '500px';

        // Initialize chart
        this.barChartInstance = echarts.init(this.barChart.nativeElement);
        this.refreshBarchart();
      } catch (err) {
        this.toastService.pushToast({
          id: 'error-toast',
          tone: 'critical',
          toastText: 'Fehler beim Initialisieren des Diagramms.'
        });
      }
    }
  }

  private refreshBarchart(): void {
    if (this.barChartInstance) {
      this.barChartInstance.setOption({
        dataset: {
          dimensions: ['timerange', 'Service', 'Gast', 'GA'],
          source: this.data
        },
        tooltip: {},
        xAxis: [
          { type: 'category', data: this.statisticsService.timeranges },
        ],
        yAxis: [{ type: 'value' }],
        series: [
          {
            id: 'Service',
            type: 'bar',
            itemStyle: {
              color: '#B7B6B6'
            },
          },
          {
            id: 'Gast',
            type: 'bar',
            itemStyle: {
              color: '#127FCF'
            },
          },
          {
            id: 'GA',
            type: 'bar',
            itemStyle: {
              color: '#2DA50F'
            },
          }
        ]
      }, true);
    }
  }
}
