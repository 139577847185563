import { Component, computed, signal } from '@angular/core';
import { DsIconModule, DsTagModule } from '@bmw-ds/components';
import { CommonModule } from '@angular/common';
import { VehicleStatus } from './vehicle-status.enum';
import { DsTagPracticalTones } from '@bmw-ds/components/ds-interfaces/tag/tag.interface';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { VehicleStay } from '@bmw-spp/bmw-spp-frontend-common';

@Component({
  selector: 'app-vehicle-status-cell-renderer',
  standalone: true,
  imports: [
    CommonModule,
    DsIconModule,
    DsTagModule,
  ],
  templateUrl: './vehicle-status-cell-renderer.component.html',
  styleUrl: './vehicle-status-cell-renderer.component.scss'
})
export class VehicleStatusCellRendererComponent  implements ICellRendererAngularComp {
  params = signal<ICellRendererParams<VehicleStay>|undefined>(undefined);

  agInit(params: ICellRendererParams<VehicleStay>): void {
    this.params.set(params);
    
  }

  refresh(params: ICellRendererParams<VehicleStay>): boolean {
    this.params.set(params);
    return true;
  }

  public status = computed(() => {
    const data = this.params()?.data;
    if (!data?.appointment?.appointmentDatetime && !data?.identifiedDatetime) {
      return;
    }
    if (data?.transitedDatetime && (!data?.exitedDatetime || data?.exitedDatetime < data?.transitedDatetime)) {
      if (data.customerIsWaiting) {
        return VehicleStatus.Waiting;
      }
      return VehicleStatus.InHouse;
    }

    if (data?.exitedDatetime) {
      return VehicleStatus.Completed;
    }

    return VehicleStatus.Open;
  });

  public tone = computed<DsTagPracticalTones>(() => {
    switch(this.status()) {
      case VehicleStatus.Open:
        return "positive";
      case VehicleStatus.Completed:
        return "muted";
      case VehicleStatus.InHouse:
        return "info";
      case VehicleStatus.Waiting:
        return "critical";
      default:
        return "muted";
    }
  });
}
