import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, computed, ElementRef, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DsBoxModule, DsCheckboxModule, DsDatepickerModule, DsFormsModule, DsTabsModule, DsToastService } from '@bmw-ds/components';
import * as echarts from 'echarts';
import { BarChartComponent } from "./bar-chart/bar-chart.component";
import { StatisticsService } from '../shared/statistics/statistics.service';
import BarChartSeries from './bar-chart/bar-chart-series.model';
import { DailyKpiComponent } from './daily-kpi/daily-kpi.component';
import { DateService } from '../shared/date/date.service';
import { VehicleStay, VehicleStayService, VisitReason } from '@bmw-spp/bmw-spp-frontend-common';

@Component({
  selector: 'app-daily-statistics',
  standalone: true,
  imports: [
    CommonModule,
    DsBoxModule,
    DsCheckboxModule,
    DsDatepickerModule,
    DsFormsModule,
    DsTabsModule,
    FormsModule,
    BarChartComponent,
    DailyKpiComponent
  ],
  templateUrl: './daily-statistics.component.html',
  styleUrl: './daily-statistics.component.scss'
})
export class DailyStatisticsComponent implements OnInit, AfterViewInit {
  selectedDate: Date = new Date();
  serviceSelected: WritableSignal<boolean> = signal(false);
  serviceCount: WritableSignal<number> = signal(0);
  guestSelected: WritableSignal<boolean> = signal(false);
  guestCount: WritableSignal<number> = signal(0);
  gaSelected: WritableSignal<boolean> = signal(false);
  gaCount: WritableSignal<number> = signal(0);
  totalCount = computed(() => {
    let total: number = 0;
    if (this.serviceSelected()) {
      total += this.serviceCount();
    }

    if (this.guestSelected()) {
      total += this.guestCount();
    }

    if (this.gaSelected()) {
      total += this.gaCount();
    }

    return total;
  });
  public vehiclesForService: VehicleStay[] = [];
  public vehiclesForGuest: VehicleStay[] = [];
  public vehiclesForGA: VehicleStay[] = [];

  @ViewChild('donutChart') donutChart: ElementRef | null = null;
  private donutChartInstance: echarts.ECharts | null = null;
  // If item in the array is a number, it represents the count of the service,
  // if item in the array is a string, it represents the name of the service
  private donutData: (number | VisitReason)[][] = [];

  public entryChartSeries: BarChartSeries[] = [];
  public entryChartData: { timerange: string, Service: number, Gast: number, GA: number }[] = [];
  public exitChartSeries: BarChartSeries[] = [];
  public exitChartData: { timerange: string, Service: number, Gast: number, GA: number }[] = [];

  constructor(private statisticsService: StatisticsService,
    private vehicleStayService: VehicleStayService,
    private dateService: DateService,
    private toastService: DsToastService,
  ) { }

  ngOnInit(): void {
    (async () => {
      this.refreshView(this.selectedDate);
    })();
  }

  ngAfterViewInit(): void {
    if (this.donutChart) {
      // First set height of chart
      this.donutChart.nativeElement.style.height = (this.donutChart.nativeElement.parentElement.clientHeight + this.donutChart.nativeElement.parentElement.offsetHeight) + 'px';

      // Initialize chart
      this.donutChartInstance = echarts.init(this.donutChart.nativeElement);
      this.refreshDonutChart();
    }
  }

  public async refreshView(date: Date) {
    try {
      this.selectedDate = date;
      const allVehicleStays = await this.vehicleStayService.loadAll();
      this.vehiclesForService = allVehicleStays.filter((vehicleStay) => vehicleStay.vehicle.visitReason === 'Service' && (vehicleStay.identifiedDatetime && this.dateService.areDatesOnSameDay(vehicleStay.identifiedDatetime, this.selectedDate)));
      this.vehiclesForGuest = allVehicleStays.filter((vehicleStay) => vehicleStay.vehicle.visitReason === 'Gast' && (vehicleStay.identifiedDatetime && this.dateService.areDatesOnSameDay(vehicleStay.identifiedDatetime, this.selectedDate)));
      this.vehiclesForGA = allVehicleStays.filter((vehicleStay) => vehicleStay.vehicle.visitReason === 'GA' && (vehicleStay.identifiedDatetime && this.dateService.areDatesOnSameDay(vehicleStay.identifiedDatetime, this.selectedDate)));

      // Signals for service and guest count
      this.serviceCount.set(this.vehiclesForService.length);
      this.guestCount.set(this.vehiclesForGuest.length);
      this.gaCount.set(this.vehiclesForGA.length);
    }
    catch (err) {
      this.toastService.pushToast({
        id: 'error-toast',
        tone: 'critical',
        toastText: 'Fehler beim Laden der Statistik.'
      });
    }
  }

  private refreshDonutChart(): void {
    if (this.donutChartInstance) {
      const categories = this.donutData.map((data: (VisitReason | number)[]) => data.filter((data) => typeof data === 'string')[0]);
      const colorsObject: { [key: string]: string } = {};
      categories.forEach((category: VisitReason | number) => {
        switch (category) {
          case 'Service':
            colorsObject['Service'] = '#B7B6B6';
            break;
          case 'Gast':
            colorsObject['Gast'] = '#127FCF';
            break;
          case 'GA':
            colorsObject['GA'] = '#2DA50F';
            break;
          default:
            colorsObject[''] = '#000000';
            break;
        }
      });
      this.donutChartInstance.setOption({
        title: {
          text: `${this.totalCount()}`,
          left: 'center',
          top: 'center',
        },
        visualMap: [
          {
            type: 'piecewise',
            dimension: 1,
            categories: categories,
            inRange: {
              color: colorsObject
            },
            show: false
          }
        ],
        series: [
          {
            type: 'pie',
            radius: ['50%', '100%'],
            label: {
              show: true,
              position: 'inside',
              fontSize: 20,
              fontWeight: 'bold',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              color: '#000',
              borderRadius: 10,
              padding: 5,
              formatter: (params: object | Array<string>) => {
                let result = "";
                if ('value' in params) {
                  result = (params['value'] as string[])[0];
                }

                return result;
              }
            },
            labelLine: {
              show: false
            },
            data: [...this.donutData],
          }
        ]
      });
    }
  }

  public async setServiceSelected(value: boolean): Promise<void> {
    this.serviceSelected.set(value);
    if (value) {
      this.donutData.push([this.serviceCount(), `Service`]);
      await this.handleDataAndSeriesForEntryChart('Service');
      await this.handleDataAndSeriesForExitChart('Service');
    } else {
      this.donutData = this.donutData.filter((data) => {
        const serviceType = data.filter((data) => typeof data === 'string' && data === `Service`);
        return serviceType.length === 0;
      });
      this.entryChartSeries = this.entryChartSeries.filter((data) => {
        return data.id !== 'Service';
      });
      this.exitChartSeries = this.exitChartSeries.filter((data) => {
        return data.id !== 'Service';
      });
      this.entryChartData = [...this.statisticsService.resetEntryData('Service')];
      this.exitChartData = [...this.statisticsService.resetExitData('Service')];
    }

    this.refreshDonutChart();
  }

  public async setGuestSelected(value: boolean): Promise<void> {
    this.guestSelected.set(value);
    if (value) {
      this.donutData.push([this.guestCount(), `Gast`]);
      await this.handleDataAndSeriesForEntryChart('Gast');
      await this.handleDataAndSeriesForExitChart('Gast');
    } else {
      this.donutData = this.donutData.filter((data) => {
        const guestType = data.filter((data) => typeof data === 'string' && data === `Gast`);
        return guestType.length === 0;
      });
      this.entryChartSeries = this.entryChartSeries.filter((data) => {
        return data.id !== 'Gast';
      });
      this.exitChartSeries = this.exitChartSeries.filter((data) => {
        return data.id !== 'Gast';
      });
      this.exitChartData = [...this.statisticsService.resetExitData('Gast')];
      this.entryChartData = [...this.statisticsService.resetEntryData('Gast')];
    }

    this.refreshDonutChart();
  }

  public async setGASelected(value: boolean): Promise<void> {
    this.gaSelected.set(value);
    if (value) {
      this.donutData.push([this.gaCount(), `GA`]);
      await this.handleDataAndSeriesForEntryChart('GA');
      await this.handleDataAndSeriesForExitChart('GA');
    } else {
      this.donutData = this.donutData.filter((data) => {
        const gaType = data.filter((data) => typeof data === 'string' && data === `GA`);
        return gaType.length === 0;
      });
      this.entryChartSeries = this.entryChartSeries.filter((data) => {
        return data.id !== 'GA';
      });
      this.exitChartSeries = this.exitChartSeries.filter((data) => {
        return data.id !== 'GA';
      });
      this.exitChartData = [...this.statisticsService.resetExitData('GA')];
      this.entryChartData = [...this.statisticsService.resetEntryData('GA')];
    }

    this.refreshDonutChart();
  }

  private async handleDataAndSeriesForEntryChart(serviceType: VisitReason) {
    try {
      switch (serviceType) {
        case "Service": {
          const entryServiceData = await this.statisticsService.loadServiceEntryData(this.selectedDate);
          if (entryServiceData && entryServiceData.length > 0) {
            this.entryChartData = [...entryServiceData];
          }
          break;
        }
        case "Gast": {
          const entryGuestData = await this.statisticsService.loadGuestEntryData(this.selectedDate);
          if (entryGuestData && entryGuestData.length > 0) {
            this.entryChartData = [...entryGuestData];
          }
          break;
        }
        case "GA": {
          const entryGAData = await this.statisticsService.loadGAEntryData(this.selectedDate);
          if (entryGAData && entryGAData.length > 0) {
            this.entryChartData = [...entryGAData];
          }
          break;
        }
      }
    } catch (err) {
      this.toastService.pushToast({
        id: 'error-toast',
        tone: 'critical',
        toastText: 'Fehler beim Laden der Statistik für Einfahrtsdaten.'
      });
    }
  }

  private async handleDataAndSeriesForExitChart(serviceType: VisitReason) {
    try {
      switch (serviceType) {
        case "Service": {
          const entryServiceData = await this.statisticsService.loadServiceExitData(this.selectedDate);
          if (entryServiceData && entryServiceData.length > 0) {
            this.exitChartData = [...entryServiceData];
          }
          break;
        }
        case "Gast": {
          const entryGuestData = await this.statisticsService.loadGuestExitData(this.selectedDate);
          if (entryGuestData && entryGuestData.length > 0) {
            this.exitChartData = [...entryGuestData];
          }
          break;
        }
        case "GA": {
          const entryGAData = await this.statisticsService.loadGAExitData(this.selectedDate);
          if (entryGAData && entryGAData.length > 0) {
            this.exitChartData = [...entryGAData];
          }
          break;
        }
      }
    } catch (err) {
      this.toastService.pushToast({
        id: 'error-toast',
        tone: 'critical',
        toastText: 'Fehler beim Laden der Statistik für Ausfahrtsdaten.'
      });
    }
  }
}