import { Component, Input, OnChanges, signal, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsBoxModule, DsButtonModule } from '@bmw-ds/components';
import { Lane } from '../shared/streams/lane.model';
import debug from 'debug';
// import DigestClient from "digest-fetch";


@Component({
  selector: 'app-stream-card',
  standalone: true,
  imports: [
    CommonModule,
    DsButtonModule,
    DsBoxModule,
  ],
  templateUrl: './stream-card.component.html',
  styleUrl: './stream-card.component.scss'
})
export class StreamCardComponent implements OnChanges {
  @Input() stream!: Lane;

  previewUrl = signal<string>("");

  previewSourceUrl = signal<string>("");
  fullScreenSourceUrl = signal<string>("");

  private mediaSource = new MediaSource();
  private userName?: string | null;
  private password?: string | null;
  
  private logDebug = debug('app:StreamCardComponent:log');
  private logError = debug('app:StreamCardComponent:error*');

  constructor() {
    this.logDebug.bind(console.log);
    this.logError.bind(console.error);
  }

  // ngOnDestroy(): void {
  //   this.removeSourceBuffers();
  // }

  ngOnChanges(changes: SimpleChanges): void {
    const streamChange = changes['stream'];
    if (streamChange.currentValue?.previewCameraUrl !== this.previewSourceUrl) {
      this.logDebug("Processing new url");
      try {
        const {
          protocol: previewProtocol,
          url: previewUrl,
          userName: previewUserName,
          password: previewPassword,
        } = this.getUrlParts(streamChange.currentValue?.previewCameraUrl);
        this.userName = previewUserName;
        this.password = previewPassword;
        this.previewSourceUrl.set(previewProtocol + previewUrl);
        const {
          protocol: fullScreenProtocol,
          url: fullScreenUrl,
        } = this.getUrlParts(streamChange.currentValue?.fullScreenCameraUrl);
        this.fullScreenSourceUrl.set(fullScreenProtocol + fullScreenUrl);
        if (typeof this.previewSourceUrl === "undefined" || this.previewSourceUrl === null) {
          this.logDebug("Source url not set");
          return;
        }
        // this.mediaSource.addEventListener("sourceopen", () => {
        //   this.aborter.abort();
        //   this.removeSourceBuffers();
        //   this.aborter = new AbortController();
        //   this.addSourceBuffer(this.currentSourceUrl());
        // });
        // this.url.set(URL.createObjectURL(this.mediaSource));
      } catch (err) {
        this.logError("Failed to process new url: %O", err);
      }
    }
  }

  private getUrlParts(fullUrl: string) {
    const firstSlashPos = fullUrl.indexOf("/", 0);
    const userNameStartPos = fullUrl.indexOf("/", firstSlashPos + 1) + 1;
    let passwordEndPos = fullUrl.indexOf("@", 0);
    let userName: string | null = null;
    let password: string | null = null;
    if (passwordEndPos > -1) {
      const userNameEndPos = fullUrl.indexOf(":", userNameStartPos);
      userName = fullUrl.substring(userNameStartPos, userNameEndPos);
      password = fullUrl.substring(userNameEndPos + 1, passwordEndPos);
    } else {
      passwordEndPos = userNameStartPos-1;
    }
    const protocol = fullUrl.substring(0, userNameStartPos);
    const url = fullUrl.substring(passwordEndPos + 1);
    return { protocol, url, userName, password };
  }

  // private async addSourceBuffer(url: string) {
  //   try {
  //     this.logDebug("Adding new source buffer");
  //     await this.loadChunks(url, this.aborter);
  //   } catch (err) {
  //     this.logError("Failed to add new source buffer: %O", err);
  //   }
  // }

  // private aborter = new AbortController();

//   private async loadChunks(url: string, { signal }: AbortController) {
//     const videoSourceBuffer = this.mediaSource.addSourceBuffer('video/mp4; codecs="av01.0.04M.08, opus"');
//     // const videoSourceBuffer = this.mediaSource.addSourceBuffer('video/mp4');
//     // const videoSourceBuffer = this.mediaSource.addSourceBuffer('video/h264');
//     let response: Response;
//     if (this.userName) {
//       // const headers = new Headers();
//       // headers.append('Authorization', 'Basic ' + window.btoa(this.userName + ':' + this.password));
//       // response = await fetch(url, {
//       //   headers
//       // });
//       const client = new DigestClient(this.userName, this.password, {
//         algorithm: 'MD5',
//         // basic: true,
//         statusCode: 200,
//         logger: console,
//       });
//       response = await client.fetch(url) as Response;
//     } else {
//       response = await fetch(url);
//     }

//     const reader = response.body?.getReader();
//     if (!reader) {
//       return;
//     }
//     while (!signal.aborted) {
//       const { done, value } = await reader.read();
//       const buffer = value?.buffer;
//       if (buffer) {
//         this.logDebug("Got buffer");
//         await this.awaitSourceBufferReady(videoSourceBuffer);
//         videoSourceBuffer.appendBuffer(buffer);
//       }
//       if (done) {
//         return;
//       }
//     }
//   }

//   private async awaitSourceBufferReady(sourceBuffer: SourceBuffer) {
//     if (!sourceBuffer.updating) {
//       return;
//     }
//     const promise = new Promise<void>((resolve) => {
//       sourceBuffer.addEventListener("updateend", () => {
//         resolve();
//       });
//     });
//     await promise;
//     await this.awaitSourceBufferReady(sourceBuffer);
//   }

//   private removeSourceBuffers() {
//     try {
//       const oldSourceBuffers = this.mediaSource.activeSourceBuffers;
//       for (let i = 0; i < oldSourceBuffers.length; i++) {
//         const oldSourceBuffer = oldSourceBuffers[i];
//         oldSourceBuffer.abort();
//         this.mediaSource.removeSourceBuffer(oldSourceBuffer);
//       }
//     } catch (err) {
//       this.logError("Failed to remove old source buffers: %O", err);
//     }
//   }
}
