import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, RowNode } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { DsButtonModule, DsIconModule } from '@bmw-ds/components';
import { VehicleStay } from '@bmw-spp/bmw-spp-frontend-common';

@Component({
  selector: 'app-context-menu-cell-renderer',
  standalone: true,
  imports: [
    DsButtonModule,
    DsIconModule,
  ],
  templateUrl: './context-menu-cell-renderer.component.html',
  styleUrl: './context-menu-cell-renderer.component.scss'
})
export class ContextMenuCellRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams<VehicleStay>;
  isContextMenuOpen = false;

  agInit(params: ICellRendererParams<VehicleStay>): void {
    this.params = params;
  }
  refresh(params: ICellRendererParams<VehicleStay>): boolean {
    this.params = params;
    return true;
  }

  public toggleContextMenu() {
    this.params.api.showContextMenu({
      rowNode: this.params.node as RowNode,
      column: this.params.column,
      value: null,
    });
    this.isContextMenuOpen = !this.isContextMenuOpen;
  }

}
