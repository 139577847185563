import { Injectable } from '@angular/core';

import { PairingUpsert } from './pairing-upsert.model';
import { VehicleStayService } from '@bmw-spp/bmw-spp-frontend-common';

@Injectable({
  providedIn: 'root'
})
export abstract class PairingService {

  constructor(
    protected vehicleService: VehicleStayService,
  ) {}

  /**
   * Creates or updates an existing pairing
   * @param licensePlate 
   * @param pairing 
   * @param isNew true if is new pairing
   */
  public async upsertPairing(licensePlate: string, pairing: PairingUpsert, isNew: boolean): Promise<void> {
    await this.startUpsertPairing(licensePlate, pairing, isNew);
    this.notifyPairingInProgress(licensePlate);
  }

  protected abstract startUpsertPairing(licensePlate: string, pairing: PairingUpsert, isNew: boolean): Promise<void>;

  protected notifyPairingInProgress(licensePlate: string): void {
    this.vehicleService.setPairingInProgress(licensePlate);
  }
}
