<div class="row">
    <div class="col-2">
        <h1>Besuchsstatistik</h1>
    </div>
    <div class="col-5">
        <b>Tagesstatistik</b>
    </div>
    <div class="col-5">
        <h1>Tagesdaten</h1>
    </div>
</div>
<ds-box class="row mb-5x">
    <ds-box-content class="col-12">
        <div class="row">
            <div class="col-2">
                <div class="row">
                    <div class="col-12">
                        <div class="row mb-3x">
                            <ds-datepicker [viewDate]="selectedDate">
                                <input ds-date [ngModel]="selectedDate" (ngModelChange)="refreshView($event)" aria-label="Eingabefeld zur Auswahl eines Datums" />
                            </ds-datepicker>
                        </div>
                        <div class="row mb-3x">
                            <div class="col-9 d-flex align-items-center">
                                <input
                                    ds-input
                                    id="service"
                                    class="me-3x"
                                    type="checkbox"
                                    name="service"
                                    [value]="serviceSelected()"
                                    [ngModel]="serviceSelected()"
                                    (ngModelChange)="setServiceSelected($event)"
                                />
                                <label ds-label for="service"><b>Service</b></label>
                            </div>
                            <div class="col-1" style="background-color: #B7B6B6; border-radius: 50%;"></div>
                            <div class="col-1">
                                <b>{{this.serviceCount()}}</b>
                            </div>
                        </div>
                        <div class="row mb-3x">
                            <div class="col-9 d-flex align-items-center">
                                <input
                                    ds-input
                                    id="guest"
                                    class="me-3x"
                                    type="checkbox"
                                    name="guest"
                                    [value]="guestSelected()"
                                    [ngModel]="guestSelected()"
                                    (ngModelChange)="setGuestSelected($event)"
                                />
                                <label ds-label for="guest"><b>Gast</b></label>
                            </div>
                            <div class="col-1" style="background-color: #127FCF; border-radius: 50%;"></div>
                            <div class="col-1">
                                <b>{{this.guestCount()}}</b>
                            </div>
                        </div>
                        <div class="row mb-3x">
                            <div class="col-9 d-flex align-items-center">
                                <input
                                    ds-input
                                    id="ga"
                                    class="me-3x"
                                    type="checkbox"
                                    name="ga"
                                    [value]="gaSelected()"
                                    [ngModel]="gaSelected()"
                                    (ngModelChange)="setGASelected($event)"
                                />
                                <label ds-label for="ga"><b>GA</b></label>
                            </div>
                            <div class="col-1" style="background-color: #2DA50F; border-radius: 50%;"></div>
                            <div class="col-1">
                                <b>{{this.gaCount()}}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-5">
                <div #donutChart></div>
            </div>
            <div class="col-5">
                <app-daily-kpi [guestVehicleStays]="vehiclesForGuest" [serviceVehicleStays]="vehiclesForService"></app-daily-kpi>
            </div>
        </div>
    </ds-box-content>
</ds-box>
<div class="row mb-5x">
    <div class="col-12">
        <h1>Ankunftszeiten</h1>
    </div>
    <div class="col-12">
        <ds-box class="row">
            <ds-box-content class="col-12">
                <app-bar-chart [chartSeries]="entryChartSeries" [data]="entryChartData"></app-bar-chart>
            </ds-box-content>
        </ds-box>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <h1>Abfahrtszeiten</h1>
    </div>
    <div class="col-12 second-barcharts">
        <ds-box class="row">
            <ds-box-content class="col-12">
                <app-bar-chart [chartSeries]="exitChartSeries" [data]="exitChartData"></app-bar-chart>
            </ds-box-content>
        </ds-box>
    </div>
</div>