import { Component, Input, input, signal } from '@angular/core';
import { DsLoadingAreaModule, DsToastModule } from '@bmw-ds/components';

import { AgGridAngular } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  ModuleRegistry,
  SideBarDef,
  SizeColumnsToContentStrategy,
} from '@ag-grid-community/core';
import { AG_GRID_LOCALE_DE } from '@ag-grid-community/locale';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { JsonPipe } from '@angular/common';
import { VehicleStay } from '@bmw-spp/bmw-spp-frontend-common';
import { HintCellRendererComponent } from '../hint-cell-renderer/hint-cell-renderer.component';
import { VehicleStatusCellRendererComponent } from '../vehicle-status-cell-renderer/vehicle-status-cell-renderer.component';
import GridExportEvent from '../visitors-and-appointments/grid-export-event';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  RowGroupingModule,
  ColumnsToolPanelModule,
  MenuModule,
  SetFilterModule,
]);

@Component({
  selector: 'app-visitors-and-appointments-ag-grid',
  standalone: true,
  imports: [AgGridAngular, DsToastModule, DsLoadingAreaModule, JsonPipe],
  templateUrl: './visitors-and-appointments-ag-grid.component.html',
  styleUrl: './visitors-and-appointments-ag-grid.component.scss',
})
export class VisitorsAndAppointmentsAgGridComponent {
  appointments = input.required<VehicleStay[]>();
  gridApi!: GridApi;
  localeText = AG_GRID_LOCALE_DE;

  constructor(private gridExportEvent: GridExportEvent) {
    this.exportGridToCsv = this.exportGridToCsv.bind(this);
    this.gridExportEvent.getEventEmitter().subscribe(this.exportGridToCsv);
    this.isExternalFilterPresent = this.isExternalFilterPresent.bind(this);
    this.doesExternalFilterPass = this.doesExternalFilterPass.bind(this);
  }

  private exportGridToCsv(): void {
    if (this.gridApi) {
      this.gridApi.exportDataAsCsv();
    }
  }

  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',

        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
        },
      },
    ],
    defaultToolPanel: '',
  };

  columns = signal<ColDef<VehicleStay>[]>([
    {
      field: 'appointment.appointmentDatetime',
      headerName: 'Termin',
      filter: 'agDateColumnFilter',
      valueGetter: (p) => p.data?.appointment?.appointmentDatetime,
      valueFormatter: (p) =>
        p.data?.appointment?.appointmentDatetime
          ? `${p.data?.appointment?.appointmentDatetime?.toLocaleDateString()} ${p.data?.appointment?.appointmentDatetime?.toLocaleTimeString(
              [],
              { hour: '2-digit', minute: '2-digit' }
            )}`
          : '',
      sort: 'asc',
    },
    {
      field: 'vehicle.customer.lastName',
      headerName: 'Kunde',
      cellRenderer: (params: ICellRendererParams) => {
        return (
          params.data.vehicle?.customer?.lastName +
          ' ' +
          params.data.vehicle?.customer?.firstName
        );
      },
    },
    {
      field: 'vehicle.customer.driver',
      headerName: 'Fahrer',
    },
    {
      field: 'vehicle.customer.companyName',
      headerName: 'Firma',
      hide: true,
    },
    {
      field: 'vehicle.visitReason',
      headerName: 'Grund',
    },
    {
      headerName: 'Status',
      hide: false,
      cellRenderer: VehicleStatusCellRendererComponent,
    },
    {
      headerName: 'Verspätung',
      cellRenderer: HintCellRendererComponent,
      cellRendererParams: {
        dateFieldKey: 'appointment.appointmentDatetime',
      },
    },
    {
      field: 'vehicle.licensePlate',
      headerName: 'Kennzeichen',
    },
    {
      field: 'vehicle.carType',
      headerName: 'Modell',
      hide: true,
    },
    {
      field: 'identifiedDatetime',
      headerName: 'Ankunftsdatum',
      filter: 'agDateColumnFilter',
      valueGetter: (params) =>
        params.data?.identifiedDatetime
          ? params.data?.identifiedDatetime
          : params.data?.transitedDatetime,
      valueFormatter: (params) => {
        if (params.data?.identifiedDatetime) {
          return params.data?.identifiedDatetime.toLocaleDateString();
        }

        if (params.data?.transitedDatetime) {
          return params.data?.transitedDatetime.toLocaleDateString();
        }

        return '';
      },
    },
    {
      field: 'identifiedDatetime',
      headerName: 'Ankunftszeit',
      valueGetter: (params) => {
        if (params.data?.identifiedDatetime) {
          return params.data?.identifiedDatetime.toLocaleString('de-DE', {
            hour: '2-digit',
            minute: '2-digit',
          });
        }

        if (params.data?.transitedDatetime) {
          return params.data?.transitedDatetime.toLocaleString('de-DE', {
            hour: '2-digit',
            minute: '2-digit',
          });
        }
        return '';
      },
    },
    {
      field: 'identifiedDatetime',
      headerName: 'Identifiziert',
      filter: 'agDateColumnFilter',
      valueGetter: (p) => p.data?.identifiedDatetime,
      valueFormatter: (p) => {
        if (p.data?.identifiedDatetime) {
          return `${p.data?.identifiedDatetime?.toLocaleDateString()} ${p.data?.identifiedDatetime?.toLocaleTimeString(
            [],
            { hour: '2-digit', minute: '2-digit' }
          )}`;
        }
        return '';
      },
      hide: true,
    },
    {
      field: 'transitedDatetime',
      headerName: 'Durchfahrt',
      filter: 'agDateColumnFilter',
      valueGetter: (p) => p.data?.transitedDatetime,
      valueFormatter: (p) => {
        if (p.data?.transitedDatetime) {
          return `${p.data?.transitedDatetime?.toLocaleDateString()} ${p.data?.transitedDatetime?.toLocaleTimeString(
            [],
            { hour: '2-digit', minute: '2-digit' }
          )}`;
        }
        return '';
      },
      hide: true,
    },
    {
      field: 'exitedDatetime',
      headerName: 'Ausfahrt',
      filter: 'agDateColumnFilter',
      valueGetter: (p) => p.data?.exitedDatetime,
      valueFormatter: (p) => {
        if (p.data?.exitedDatetime) {
          return `${p.data?.exitedDatetime?.toLocaleDateString()} ${p.data?.exitedDatetime?.toLocaleTimeString(
            [],
            { hour: '2-digit', minute: '2-digit' }
          )}`;
        }
        return '';
      },
      hide: true,
    },
    {
      field: 'vehicle.internalContact',
      headerName: 'Interner Kontakt',
    },
  ]);
  gridOptions = signal<GridOptions>({});
  defaultColDef = signal<ColDef>({
    sortable: true,
    filter: true,
    suppressNavigable: true,
    editable: false,
  });
  statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalRowCountComponent',
      },
    ],
  };

  autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents',
  };

  public paginationPageSize = 50;
  public paginationPageSizeSelector: number[] | boolean = [
    25, 50, 100, 200, 500, 1000,
  ];

  public getRowId: GetRowIdFunc = (params: GetRowIdParams<VehicleStay>) =>
    params.data.id;

  _textFilter: string = '';
  @Input()
  public set textFilter(textFilter: string) {
    if (this.gridApi) {
      this._textFilter = textFilter;
      this.gridApi.onFilterChanged();
    }
  }
  get textFilter(): string {
    return this._textFilter;
  }
  isExternalFilterPresent(): boolean {
    return (
      this.textFilter !== ''
    );
  }

  doesExternalFilterPass(node: IRowNode): boolean {
    let hasTextFilter: boolean = false;
    if (node.data) {

      const nodeVehicleStay: VehicleStay = node.data as VehicleStay;
      const licensePlate: string = nodeVehicleStay.vehicle
        .licensePlate as string;

      if (this.textFilter !== '') {
        const vin = nodeVehicleStay.vehicle.vin
          ? nodeVehicleStay.vehicle.vin
          : '';
        const termin =
          nodeVehicleStay.appointment?.appointmentDatetime?.toLocaleTimeString(
            [],
            { hour: '2-digit', minute: '2-digit' }
          ) || '';
        const Ankunftsdatum =
          (nodeVehicleStay.identifiedDatetime as Date)?.toISOString() || '';
        const Ankunftszeit =
          (nodeVehicleStay.identifiedDatetime as Date)?.toLocaleString(
            'de-DE',
            { hour: '2-digit', minute: '2-digit' }
          ) || '';
        const kennzeichen = nodeVehicleStay?.vehicle?.licensePlate || '';

        const firstName = nodeVehicleStay.vehicle.customer?.firstName || '';
        const lastName = nodeVehicleStay.vehicle.customer?.lastName || '';
        const companyName = nodeVehicleStay.vehicle.customer?.companyName || '';
        const driver = nodeVehicleStay.vehicle?.customer?.driver || '';
        hasTextFilter =
          licensePlate.toLowerCase().indexOf(this.textFilter.toLowerCase()) >=
            0 ||
          vin.toLowerCase().indexOf(this.textFilter.toLowerCase()) >= 0 ||
          termin.includes(this.textFilter.toLowerCase()) ||
          Ankunftsdatum.includes(this.textFilter.toLowerCase()) ||
          Ankunftszeit.includes(this.textFilter.toLowerCase()) ||
          firstName.toLowerCase().includes(this.textFilter.toLowerCase()) ||
          lastName.toLowerCase().includes(this.textFilter.toLowerCase()) ||
          companyName.toLowerCase().includes(this.textFilter.toLowerCase()) ||
          driver.toLowerCase().includes(this.textFilter.toLowerCase()) ||
          kennzeichen.toLowerCase().indexOf(this.textFilter.toLowerCase()) >= 0;
      }

        return  hasTextFilter;
    }

    return true;
  }
}
