<ds-tabs>
    <ds-tabs-label for="serviceTab">Service</ds-tabs-label>
    <ds-tabs-content id="serviceTab">
        <div class="row w-100">
            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        Pünktlichkeit
                    </div>
                    <div class="col-8">
                        {{servicePunctuality()}}%
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        Termine Ges.
                    </div>
                    <div class="col-8">
                        {{serviceVehicleStays().length}}
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        Termine eingetr.
                    </div>
                    <div class="col-8">
                        {{serviceArrived()}}
                        <span *ngIf="serviceVehicleStays().length > 0">({{serviceArrived() / serviceVehicleStays().length * 100}}%)</span>
                        <span *ngIf="serviceVehicleStays().length === 0">(0%)</span>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        Kunden o. T.
                    </div>
                    <div class="col-8">
                        {{serviceWithoutAppointment()}}
                        <span *ngIf="serviceVehicleStays().length > 0">({{serviceWithoutAppointment() / serviceVehicleStays().length * 100}}%)</span>
                        <span *ngIf="serviceVehicleStays().length === 0">(0%)</span>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        Verheiratungen
                    </div>
                    <div class="col-8">
                        {{servicePairing()}}
                        <span *ngIf="serviceVehicleStays().length > 0">({{servicePairing() / serviceVehicleStays().length * 100}}%)</span>
                        <span *ngIf="serviceVehicleStays().length === 0">(0%)</span>
                    </div>
                </div>
            </div>
        </div>
    </ds-tabs-content>
    <ds-tabs-label for="guestTab">Gast</ds-tabs-label>
    <ds-tabs-content id="guestTab">
        <div class="row w-100">
            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        Eingetroffen
                    </div>
                    <div class="col-8">
                        {{guestArrived()}}
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        Verheiratungen
                    </div>
                    <div class="col-8">
                        {{guestPaired()}}
                    </div>
                </div>
            </div>
            <!-- OOS <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        Verdachtsfälle
                    </div>
                    <div class="col-8">
                        48 (67%)
                    </div>
                </div>
            </div> -->
        </div>
    </ds-tabs-content>
</ds-tabs>