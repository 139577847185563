
<ds-box>
    <ds-box-header>Ein- und Ausfahrten</ds-box-header>
    <ds-box-content>
        <div class="container-fluid">
            <div class="row">
                <div *ngFor="let stream of streams" [class]="getColClass()">
                    <app-stream-card [stream]="stream"></app-stream-card>
                </div>
            </div>
        </div>
    </ds-box-content>
</ds-box>