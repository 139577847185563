<ds-box>
    <ds-box-header>Fahrzeuge</ds-box-header>
    <ds-box-content>
        <ag-grid-angular
            class="ag-theme-density spp-grid"
            domLayout="normal"
            [rowData]="vehicle()"
            [columnDefs]="columns()"
            [gridOptions]="gridOptions()"
            [rowHeight]="70"
            [autoSizeStrategy]="autoSizeStrategy"
            [defaultColDef]="defaultColDef()"
            [paginationPageSize]="paginationPageSize"
            [paginationPageSizeSelector]="paginationPageSizeSelector"
            [pagination]="true"
            [localeText]="localeText"
            [getRowId]="getRowId"
        >
        </ag-grid-angular>
    </ds-box-content>
</ds-box>
