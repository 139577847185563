import { Component, computed, Input } from '@angular/core';
import { DsTagModule } from '@bmw-ds/components';
import { DateService } from '../shared/date/date.service';
import { TagTone } from '@bmw-ds/components/ds-interfaces/tag/tag.interface';

@Component({
  selector: 'app-time-hint-tag',
  standalone: true,
  imports: [
    DsTagModule
  ],
  templateUrl: './time-hint-tag.component.html',
  styleUrl: './time-hint-tag.component.scss'
})
export class TimeHintTagComponent {
  @Input() expectedDate?: Date | undefined = undefined;
  @Input() compareDate: Date | undefined;

  constructor(private dateService: DateService) { }

  public tone = computed<TagTone>(() => {
    const expectedDate = this.expectedDate ?? new Date();
    const compareDate = this.compareDate ?? new Date();
    const deltaMinutes = this.dateService.getTimeDeltaInMinutes(expectedDate, compareDate);
    if (deltaMinutes >= 0) {
      return 'positive';
    }
    if (deltaMinutes > -15) {
      return 'caution';
    }
    return 'critical';
  });

  public remainingTime = computed<string>(() => {
    const now = new Date();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (this.expectedDate === undefined || this.expectedDate == null || !(this.expectedDate instanceof Date) || isNaN(this.expectedDate as any)) {
      return 'N/A';
    }

    let result: string = 'N/A';
    if (this.compareDate && this.expectedDate) {
      if (this.compareDate > this.expectedDate) {
        const delta = this.compareDate.getTime() - this.expectedDate.getTime();
        result = `${this.dateService.getTimeInMSToDayHoursAndMinutes(delta)}`;
      } else {
        const delta = this.expectedDate.getTime() - this.compareDate.getTime();
        result = `+ ${this.dateService.getTimeInMSToDayHoursAndMinutes(delta)}`;
      }
    } else {
      if (now > this.expectedDate) {
        const delta = now.getTime() - this.expectedDate.getTime();
        result = `+ ${this.dateService.getTimeInMSToDayHoursAndMinutes(delta)}`;
      } else {
        const delta = this.expectedDate.getTime() - now.getTime();
        result = `${this.dateService.getTimeInMSToDayHoursAndMinutes(delta)}`;
      }
    }

    return result;
  });
}
