import { Injectable, Signal } from '@angular/core';
import { LocationProvider } from './location-provider.model';
import { DataService } from '@bmw-spp/bmw-spp-frontend-common';

@Injectable({
  providedIn: 'root'
})
export abstract class LocationProviderService extends DataService<LocationProvider> {
  public abstract locationProviderMap: Signal<Map<string, LocationProvider>>;

  public abstract isValidName(value: string): Promise<boolean>;
}
